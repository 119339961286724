import * as React from 'react';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Hero from '../components/Hero/Hero';
import ImageText from '../components/ImageText/ImageText';

const NotFoundPage = () => (
  <Layout>
    <div className="page">
      <Hero className={'bg-secondary'} text={'<h1>404</h1>'}/>
      <ImageText
        className="mt-25 mt-desktop-0 text-full"
        data={{
          headline: {
            html: '<h2>Page not found</h2>',
          },
          paragraph: {
            html: '<p>Sorry, the page you are looking for doesn’t exist or has been moved.</p>'
          },
          cta_url: {
            url: '/',
            target: '_self'
          },
          cta_text: {
            text: 'Take me home'
          }
        }}
      />
    </div>
  </Layout>
)

export const Head = () => <SearchEngineOpt title="404: Not Found" />

export default NotFoundPage

