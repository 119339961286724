// extracted by mini-css-extract-plugin
export var backgroundImage = "ImageText-module--backgroundImage--6b33e";
export var button = "ImageText-module--button--4e8eb";
export var content = "ImageText-module--content--76694";
export var image = "ImageText-module--image--43201";
export var imageContainer = "ImageText-module--imageContainer--1717a";
export var imageText = "ImageText-module--imageText--d3c8a";
export var info = "ImageText-module--info--6f547";
export var sectionWrapper = "ImageText-module--sectionWrapper--9b6ea";
export var text = "ImageText-module--text--93849";
export var title = "ImageText-module--title--cbce6";