import {useState, useEffect} from 'react';
export const sizes = {
  tablet: '768px',
  desktop: '992px',
  large: '1200px',
  max: '1440px',
};

export const useMediaQuery = (screen) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
      const query = `(min-width: ${sizes[screen]})`;
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
          setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener('resize', listener);
  }, [matches, screen]);

  return matches;
};